import React, { FC } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Transition } from "../../components/Modals/Transition";
import { Form, Formik } from "formik";
import { Fields, initialValues, validationSchema } from "./schema";
import {
  btnStyle,
  btnSx,
  container,
  content,
  dscField,
  dscSelector,
} from "./EditPaymentSettings.styles";
import { SystemStyleObject, Theme } from "@mui/system";
import { getButtonVariant } from "../../../../lib";
import {
  DollarSignIcon,
  PaymentSavedCardBlock,
  PercentIcon,
} from "../../../../components";
import useEditPaymentSettings from "./useEditPaymentSettings";
import DeleteDialog from "../../components/Modals/DeleteDialog";
import { MonthsAutocomplete } from "../../components/MonthsAutocomplete";

interface Props {
  showModal: boolean;
  customerId: number;
  studentId: number;
  name: string;
  onClose?: () => void;
  hideDiscount?: boolean;
}

const EditPaymentSettings: FC<Props> = ({
  showModal,
  customerId,
  studentId,
  name,
  onClose,
  hideDiscount,
}) => {
  const {
    innerRef,
    open,
    deleteData,
    handleSubmit,
    handlePaymentMethod,
    handleDiscountType,
    handleClose,
    handleCloseDeleteModal,
    deleteFunction,
    payment_method_id,
    setPayment_method_id,
    currentMonth,
  } = useEditPaymentSettings(showModal, customerId, studentId, onClose);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      PaperProps={{
        sx: {
          maxWidth: "unset",
        },
      }}
    >
      <DialogTitle>{`${name}’s payment settings`}</DialogTitle>

      <Formik<Fields>
        innerRef={innerRef}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({
          submitForm,
          values,
          handleChange,
          handleBlur,
          touched,
          errors,
          isValid,
          setFieldValue,
        }) => {
          return (
            <Form>
              <DialogContent sx={content}>
                <Grid container sx={container} direction={"column"}>
                  <Grid item>
                    <Typography variant={"h6"} sx={{ mb: 2 }}>
                      Payment method
                    </Typography>

                    <ButtonGroup
                      sx={[
                        btnStyle as SystemStyleObject<Theme>,
                        { mb: 4 } as SystemStyleObject<Theme>,
                      ]}
                    >
                      <Button
                        variant={getButtonVariant(
                          values.default_payment_method === "cash",
                        )}
                        onClick={handlePaymentMethod.bind(null, "cash")}
                      >
                        Cash
                      </Button>

                      <Button
                        variant={getButtonVariant(
                          values.default_payment_method === "credit",
                        )}
                        onClick={handlePaymentMethod.bind(null, "credit")}
                      >
                        Credit card
                      </Button>
                    </ButtonGroup>
                  </Grid>

                  {values.default_payment_method === "credit" && (
                    <PaymentSavedCardBlock
                      payment_method_id={payment_method_id}
                      setPayment_method_id={setPayment_method_id}
                    />
                  )}

                  {!hideDiscount && (
                    <Grid item>
                      <Typography variant={"h6"}>Monthly discount</Typography>
                      <Box display="flex" alignItems="end" gap={2}>
                        {" "}
                        <TextField
                          name="discount"
                          placeholder={"Discount"}
                          value={values.discount}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          sx={dscField}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <ButtonGroup sx={dscSelector}>
                                  <Button
                                    variant={getButtonVariant(
                                      values.discount_type === "amount",
                                    )}
                                    onClick={handleDiscountType.bind(
                                      null,
                                      "amount",
                                    )}
                                  >
                                    <DollarSignIcon className={"dollar-icon"} />
                                  </Button>

                                  <Button
                                    variant={getButtonVariant(
                                      values.discount_type === "percentage",
                                    )}
                                    onClick={handleDiscountType.bind(
                                      null,
                                      "percentage",
                                    )}
                                  >
                                    <PercentIcon />
                                  </Button>
                                </ButtonGroup>
                              </InputAdornment>
                            ),
                          }}
                          error={touched.discount && !!errors.discount}
                        />
                        <TextField
                          value={values.reason || ""}
                          fullWidth
                          label="Discount reason"
                          onChange={(e) => {
                            setFieldValue("reason", e.target.value || "");
                          }}
                        />
                      </Box>

                      <Grid item maxWidth="50%" pr={1} mb={2}>
                        <MonthsAutocomplete
                          value={values.end_month_id}
                          label={"Charge in full starting in"}
                          startMonthId={currentMonth || undefined}
                          onChange={(_e, s, _r) => {
                            setFieldValue("end_month_id", s?.value || "");
                          }}
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>

                <Divider
                  variant="middle"
                  sx={{ border: "1px solid #DDE3EB", mb: 2 }}
                />

                <DialogActions>
                  <Button
                    variant="outlined"
                    color="primary"
                    sx={btnSx}
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>

                  <Button
                    variant="contained"
                    color={"primary"}
                    sx={btnSx}
                    onClick={submitForm}
                    disabled={!isValid}
                  >
                    save
                  </Button>
                </DialogActions>
              </DialogContent>
            </Form>
          );
        }}
      </Formik>

      <DeleteDialog
        showModal={!!deleteData}
        name={name}
        subject={"card"}
        onClose={handleCloseDeleteModal}
        deleteFunction={deleteFunction}
      />
    </Dialog>
  );
};

export default React.memo(EditPaymentSettings);
