import React, { useCallback, useEffect, useRef, useState } from "react";
import { FormikProps } from "formik";
import { Fields } from "./schema";
import {
  adminActions,
  adminSelectors,
  BillingDetails,
  customerActions,
  customerSelectors,
  DiscountType,
  NewPaymentCard,
  PaymentMethod,
  UpdatedPaymentData,
} from "../../../../state";
import { useDispatch, useSelector } from "react-redux";
import { toFloat } from "../../../../lib";

export default function useEditPaymentSettings(
  showModal: boolean,
  customerId: number,
  studentId: number,
  onClose?: () => void,
) {
  const [payment_method_id, setPayment_method_id] = useState<null | number>(
    null,
  );
  const currentMonth = useSelector(adminSelectors.currentMonthId);
  const [open, setOpen] = useState<boolean>(showModal);
  const [showAddCardModal, setShowAddCardModal] = useState(false);
  const [deleteData, setDeleteData] = useState<{
    id: string;
    cardId: string;
  } | null>(null);

  const customersStudentBillingInfo = useSelector(
    customerSelectors.student_billing,
  );
  const innerRef = useRef<FormikProps<Fields>>(null);

  const dispatch = useDispatch();

  const gridCallBackFunc = (
    type: "default" | "delete",
    id: string,
    cardId: string,
  ) => {
    if (!!cardId && !!id) {
      if (type === "delete") {
        setDeleteData({
          id,
          cardId,
        });
      }

      if (type === "default") {
        setDefaultCardById(cardId);
      }
    }
  };

  const getCardsList = async (updateFormik: boolean = true) => {
    const cardInfo: any = await dispatch(
      customerActions.getCustomerCardInfo(customerId),
    );

    if (cardInfo) {
      const nci = (cardInfo as unknown as NewPaymentCard[]).map((c) => {
        return {
          ...c,
          personId: customerId,
          _callBackFunc: gridCallBackFunc,
        } as unknown as NewPaymentCard;
      });

      updateFormik && (await innerRef?.current?.setFieldValue("cards", nci));

      return nci;
    }
  };

  const handleClose = (e?: React.MouseEvent<HTMLButtonElement>) => {
    e?.stopPropagation();

    onClose ? onClose() : setOpen(false);
  };

  const handleSubmit = async ({
    default_payment_method,
    // cards,
    discount,
    end_month_id,
    discount_type,
    discount_id,
    reason,
  }: Fields) => {
    const data: UpdatedPaymentData = {
      default_payment_method: default_payment_method,
      payment_method_id,
      discounts: null,
    };
    if (discount) {
      data.discounts = [
        {
          discount: toFloat(discount),
          discount_type,
          id: discount_id,
          end_month_id: end_month_id ? +end_month_id : undefined,
          reason,
        },
      ];
    }
    const isOk: any = await dispatch(
      adminActions.updateStudentBilling(studentId, data),
    );

    isOk && handleClose();
  };

  const handlePaymentMethod = (
    method: PaymentMethod,
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    e.stopPropagation();

    innerRef?.current?.setFieldValue("default_payment_method", method);
  };

  const handleDiscountType = (
    discount_type: DiscountType,
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    e.stopPropagation();

    innerRef?.current?.setFieldValue("discount_type", discount_type);
  };

  const handleOpenAddCardModal = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      setShowAddCardModal(true);
    },
    [],
  );

  const handleCloseAddCardModal = useCallback(
    async (e?: React.MouseEvent<HTMLElement>) => {
      e?.stopPropagation();

      setShowAddCardModal(false);

      await getCardsList(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customerId],
  );

  const handleCloseDeleteModal = useCallback(() => {
    setDeleteData(null);
  }, []);

  const deleteFunction = useCallback(async () => {
    if (deleteData) {
      const data = await dispatch(
        customerActions.deleteCustomerCard(deleteData.id, deleteData.cardId),
      );

      !data && (await getCardsList());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteData]);

  const setDefaultCardById = (cardId: string) => {
    const cards = innerRef.current?.values?.cards ?? [];

    if (cards.length) {
      const nc = cards.map((c) => ({ ...c, default: c.id === cardId }));

      innerRef.current?.setFieldValue("cards", nc);
    }
  };

  useEffect(() => {
    if (customerId) {
      dispatch(customerActions.getCustomerBillingDetails(customerId));
    }
  }, [customerId, dispatch]);
  useEffect(() => {
    dispatch(adminActions.getCurrentMonth());
  }, [dispatch]);

  useEffect(() => {
    !showModal && innerRef?.current?.resetForm();

    (async () => {
      if (showModal && innerRef?.current) {
        const cards = await getCardsList(false);
        const data = {} as Omit<Fields, "cards">;
        if (customersStudentBillingInfo as unknown as BillingDetails) {
          const student = customersStudentBillingInfo?.filter(
            (s) => s.id === studentId,
          )?.[0];

          if (student) {
            const { reason, month_end, id, discount_type } =
              student.discounts?.[0] || {};
            data.default_payment_method = student.default_payment_method ?? "";
            data.discount = student.discounts?.[0]?.discount.toString() ?? "";
            data.discount_type = discount_type ?? "";
            data.discount_id = id ?? null;
            data.end_month_id = month_end || "";
            data.reason = reason || "";
            setPayment_method_id(student?.payment_method_id);
          }
        }

        await innerRef?.current.setValues({
          ...innerRef?.current.values,
          ...data,
          cards: (cards as any) ?? [],
        });
      }
    })();

    setOpen(showModal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal, customersStudentBillingInfo]);

  return {
    currentMonth,
    innerRef,
    open,
    showAddCardModal,
    deleteData,
    handleSubmit,
    handlePaymentMethod,
    handleDiscountType,
    handleClose,
    setDefaultCardById,
    handleOpenAddCardModal,
    handleCloseAddCardModal,
    handleCloseDeleteModal,
    deleteFunction,
    payment_method_id,
    setPayment_method_id,
  };
}
